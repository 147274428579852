<template>
	<div class="mainTem">
		<div class="tableConTop" style="position: sticky;top:0;z-index: 10;">
			<el-row>
				<el-col :span="18" class="tableConTopLeft">
					<h3>
						<el-button type="primary" round icon="el-icon-back" size="mini" @click="goBack()">
							{{$t('i18nn_ca5a01a5adf20fe7')}}
						</el-button>

						<!-- <span class="tct_tit">{{editId?$t('i18nn_95fb447f1661affd'):$t('i18nn_bab35eeb688f9eae')}}</span> -->
						<span class="tct_tit" v-if="editId">{{$t('i18nn_95fb447f1661affd')}}</span>
						<span class="tct_tit" v-else>{{$t('i18nn_bab35eeb688f9eae')}}</span>
						<!-- <el-tooltip effect="dark" :content="$t('btn.long_Export_excel')"ng_Export_excel')" placement="top">
							<el-button type="warning" circle icon="el-icon-download" size="mini"
								@click="exportExcelAction()"></el-button>
						</el-tooltip> -->
						<span class="tct_tit" v-if="form.outWhCode" style="color:#409EFF;">
							<!-- <el-tag> -->
							<span>
								{{$t('i18nn_b1131a0cc3945b5f')}}
							</span>:
							<span>
								{{form.outWhCode}}
							</span>
							<!-- </el-tag> -->
						</span>
					</h3>
				</el-col>
				<el-col :span="6" class="tableConTopRig">
					<!-- <div class="" style="text-align: right;"> -->
						<div v-if="0==stepActive">
							<el-button type="primary" @click="nextStep">{{$t('4639f3a6e07c00b3')}}</el-button>
						</div>
						<div v-else-if="1==stepActive">
							<el-button @click="preStep">{{$t('i18nn_70e058bc23d52c1e')}}</el-button>
							<el-button type="warning" @click="submitFormAction()"
								v-if="!isOnlyView">{{$t('i18nn_e186babc2e9cadf4')}}
							</el-button>
						</div>
					<!-- </div> -->
				</el-col>
			</el-row>
			<!-- <div style="width: 80%; min-width:500px; max-width: 1000px; margin: 0 auto">
				<el-steps :active="stepActive" simple finish-status="success">
				  <el-step :title="$t('i18nn_8e23aeb472e03f34')"></el-step>
				  <el-step :title="$t('i18nn_6feb2a7c40070fa2')"></el-step>
				  <el-step :title="$t('ad4345dbaabe1479')"></el-step>
				</el-steps>
				
			</div> -->
		</div>



		<el-form ref="form" :disabled="isOnlyView" :rules="formRules" :model="form" inline label-width="210px"
			style="width: 100%;" v-loading="loading" :element-loading-text="$t('i18nn_1b4525c800280581')">



			<div v-show="0==stepActive">
				<el-card shadow="never" style="margin-top:5px;" :body-style="{ padding: '0 5px' }">
					<div slot="header" class="">
						<div style="display: flex;">
							<!-- <h3>{{$t('i18nn_8e23aeb472e03f34')}}</h3> -->
							<h3>{{$t('i18nn_8e23aeb472e03f34')}}</h3>
							<el-badge type="primary" :value="form.recordList.length">

							</el-badge>

							<!-- <div v-if="form.outWhCode" style="color:#409EFF;">
								<span>
									{{$t('i18nn_b1131a0cc3945b5f')}}
								</span>:
								<span>
									{{form.outWhCode}}
								</span>
							</div> -->
						</div>

					</div>
					<div>
						<div style="">


							<!-- <div style="margin-bottom:10px" v-if="!isOnlyView">
								<el-button icon="el-icon-plus" size="small" type="primary" @click="openDrawer()">
									{{$t('i18nn_b498df927d973f01')}}
								</el-button>
							</div> -->
							<el-table border :data="form.recordList" size="small" :height="$store.state.frameConHeightWh9"
								style="width: 100%;" :empty-text="$t('i18nn_80755d56db88fca2')">
								<el-table-column type="index" fixed="left" width="50" align="center"
									:label="$t('Storage.tableColumn.no')"></el-table-column>



								<el-table-column prop="ctnMark" :label="$t('i18nn_73fc38e67381ae73')" width="">
								</el-table-column>

								<el-table-column prop="inWhCode" :label="$t('i18nn_35ec768075eb8f4e')" width="">
								</el-table-column>

								<el-table-column prop="trCtnCount" :label="$t('i18nn_bdc361ba04506136')" width="160">
									<template slot-scope="scope">
										<!-- <el-input-number size="small" v-model="scope.row.trCtnCount" maxlength="">
										</el-input-number> -->
										{{scope.row.trCtnCount}}
									</template>
								</el-table-column>

								<el-table-column prop="goodsSku" :label="'SKU'" width="">
								</el-table-column>

								<el-table-column :label="$t('Storage.tableColumn.operation')" width="120px" fixed="right" align="left"
									v-if="!isOnlyView">
									<template slot-scope="scope">
										<div>
											<el-button @click="delDet($event, scope.row,scope.$index)" type="danger" size="small"
												icon="el-icon-minus">{{$t("FormMsg.Delete")}}</el-button>
										</div>
									</template>
								</el-table-column>
							</el-table>
							<!-- <div>
								{{$t('i18nn_9618edd493c9d6ea')}}<strong>{{form.recordList.length}}</strong>{{$t('i18nn_3e48b039f79c39a0')}}
							</div> -->
						</div>
					</div>
				</el-card>

				<el-card shadow="never" style="margin-top:5px;" v-if="!isOnlyView" :body-style="{ padding: '0 5px' }">
					<div slot="header" class="">
						<div style="display: flex;justify-content: space-between;">
							<div style="display: flex;">
								<h3>{{$t('i18nn_4118399786a5251a')}}</h3>
								<!-- <div>
									<span class="red-required">{{$t('i18nn_fd6620afef3fab05')}}</span>
									<whNoSelect ref="whNoSelect" :size="'mini'" @loadSuccess="whLoadSuccess" @changeData="changWhNo"></whNoSelect>
								</div> -->
								<ul style="display: flex;">
									<li style="padding: 0 5px;">
										<span class="red-required">{{$t('i18nn_fd6620afef3fab05')}}</span>
										<whNoSelect ref="whNoSelect" :size="'mini'" @loadSuccess="whLoadSuccess" @changeData="changWhNo"></whNoSelect>
									</li>
									<li style="padding: 0 5px;">
										<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
										<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="serKeyword"
											maxlength="50" :clearable="true" @keyup.enter.native="serTranInv()" style="width: 250px;">
										</el-input>
									</li>
									<li style="padding: 0 5px;">
										<el-button icon="el-icon-search" size="small" type="primary" @click="serTranInv()"></el-button>
									</li>
									
									<!-- <li style="padding: 0 5px;"> -->
										<!-- <span>{{$t('i18nn_9d3249981866af60')}}</span>：
										<el-radio-group v-model="form.trStockSour" @change="changeTrStockSour">
											<el-radio v-for="item in selectOption.wh_tr_stock_source" :key="item.code"
												:label="item.code">{{ $Utils.i18nCodeText(item) }}</el-radio>
										</el-radio-group> -->
										<!-- <span>{{$t('i18nn_9d3249981866af60')}}</span>： -->
										<!-- <el-switch
										  style="display: block"
										  v-model="form.trStockSour"
										  active-color="#13ce66"
										  inactive-color="#E6A23C"
											:active-value="'10'"
											:inactive-value="'20'"
										  :active-text="$t('i18nn_0950e42c6423c2a7')"
										  :inactive-text="$t('i18nn_f608e17fc712987a')">
										</el-switch> -->
									<!-- </li> -->
								</ul>
							</div>
							
							<!-- 写死，转运库存 -->
							<!-- <div>
								<el-switch
								  style="display: block"
								  v-model="form.trStockSour"
								  active-color="#13ce66"
								  inactive-color="#E6A23C"
									:active-value="'10'"
									:inactive-value="'20'"
								  :active-text="$t('i18nn_0950e42c6423c2a7')"
								  :inactive-text="$t('i18nn_f608e17fc712987a')">
								</el-switch>
							</div> -->
						</div>

					</div>
					<div>


						<!-- <div> -->
							<!-- <div> -->

								<!-- <div style="padding:10px;">
									<span>{{$t('i18nn_9d3249981866af60')}}</span>：
									<el-radio-group v-model="form.trStockSour" @change="changeTrStockSour">
										<el-radio v-for="item in selectOption.wh_tr_stock_source" :key="item.code"
											:label="item.code">{{ $Utils.i18nCodeText(item) }}</el-radio>
									</el-radio-group>
								</div> -->

								<!-- 转运入库的数据  -->

								<!-- <el-tabs v-model="form.trStockSour" @tab-click="handleClick">
									<el-tab-pane :label="$t('i18nn_0950e42c6423c2a7')" name="10">
										<TransportInWhDetListScroll :cusUserId="cusUserId" :openTime="TransportInWhDetOpenTime" :whNo="TransportInWhDetWhNo"
											@changeNum="TransportInvChange" ref="TransportInWhDetListScroll">
										</TransportInWhDetListScroll>
									</el-tab-pane>
									<el-tab-pane :label="$t('i18nn_f608e17fc712987a')" name="20">
										<TransportSkuInventoriesListScroll :cusUserId="cusUserId" :openTime="TransportInWhDetOpenTime" :whNo="TransportInWhDetWhNo"
											@changeNum="SkuInvChange" ref="TransportSkuInventoriesListScroll">
										</TransportSkuInventoriesListScroll>
									</el-tab-pane>
								</el-tabs> -->
								<!-- 转运库存 -->
								<!-- <div v-show="'10'==form.trStockSour"> -->
									<TransportInWhDetListScroll :cusUserId="cusUserId" :openTime="TransportInWhDetOpenTime" :whNo="TransportInWhDetWhNo"
										:serKeyword="serKeyword"
										@changeNum="TransportInvChange" ref="TransportInWhDetListScroll">
									</TransportInWhDetListScroll>
								<!-- </div> -->
								<!-- 在库库存 -->
								<!-- <div v-show="'20'==form.trStockSour">
									<TransportSkuInventoriesListScroll :cusUserId="cusUserId" :openTime="TransportInWhDetOpenTime" :whNo="TransportInWhDetWhNo"
										:serKeyword="serKeyword"
										@changeNum="SkuInvChange" ref="TransportSkuInventoriesListScroll">
									</TransportSkuInventoriesListScroll>
								</div> -->

								<!-- <div v-if="'10'==form.trStockSour">
									<TransportInWhDetListScroll :openTime="TransportInWhDetOpenTime" :whNo="TransportInWhDetWhNo"
										ref="TransportInWhDetListScroll">
									</TransportInWhDetListScroll>
									<div style="margin: 10px;">
										<el-button icon="el-icon-check" type="warning" @click="addRec()">
											{{$t('i18nn_760f75dc397a9cb8')}}</el-button>
									</div>
								</div>
								<div v-else-if="'20'==form.trStockSour">
									<TransportSkuInventoriesListScroll :openTime="TransportInWhDetOpenTime"
										:whNo="TransportInWhDetWhNo" ref="TransportSkuInventoriesListScroll">
									</TransportSkuInventoriesListScroll>
									<div style="margin: 10px;">
										<el-button icon="el-icon-check" type="warning" @click="addRecSkuInv()">
											{{$t('i18nn_760f75dc397a9cb8')}}</el-button>
									</div>
								</div> -->
							<!-- </div> -->
						<!-- </div> -->
					</div>
				</el-card>

				<!-- <div style="margin-top: 12px; text-align: center;">
					<el-button type="primary" @click="nextStep">{{$t('4639f3a6e07c00b3')}}</el-button>
				</div> -->
			</div>
			<div v-show="1==stepActive">
				<el-card style="margin-top:5px;">
					<div slot="header" class="">
						<h3>{{$t('i18nn_92958dfd9227a641')}}</h3>
					</div>
					<div style="display: flex;">
						<div style="width: 580px;">


							<!-- <el-form-item :label="$t('i18nn_643e08ba53f72f39')" prop="">
									<el-select filterable clearable v-model="form.inWhType"
										:placeholder="$t('FormMsg.Please_select')" style="width: 150px;">
										<el-option v-for="item in selectOption.wh_transfer_Inwh_type" :key="item.code"
											:label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
									</el-select>
								</el-form-item> -->


							<el-form-item prop="" required>
								<template slot="label">
									<span>
										<span>{{$t('i18nn_e1d76a34da86da92')}}</span><span>FBA</span>
									</span>
								</template>
								<el-radio-group v-model="form.isFba">
									<el-radio :label="'0'">{{$t('i18nn_e76d885ae1c74d4f')}}</el-radio>
									<el-radio :label="'1'">{{$t('i18nn_02ccd2cf723f9272')}}</el-radio>
								</el-radio-group>
								<!-- <el-switch v-model="form.isFba" :active-value="'1'" :inactive-value="'0'" :active-text="$t('i18nn_02ccd2cf723f9272')"
										:inactive-text="$t('i18nn_e76d885ae1c74d4f')">
									</el-switch> -->
							</el-form-item>
							<el-form-item prop="" v-if="'1'==form.isFba" required>
								<template slot="label">
									<span>
										FBA<span>{{$t('i18nn_5acbec83efb27445')}}</span>
									</span>
								</template>
								<el-input size="" clearable v-model="form.fbaAddrCode">
									<el-button slot="append" type="primary" icon="el-icon-search"
										@click="searchFbaAddress()">{{$t('i18nn_ffe3865fea625e35')}}</el-button>
								</el-input>
								<!-- <el-button type="primary" icon="el-icon-search" @click="searchFbaAddress()">{{$t('i18nn_ffe3865fea625e35')}}</el-button> -->

							</el-form-item>
							<!-- <el-form-item :label="$t('i18nn_7cebbad6657bdbf4')" prop="">
									<el-date-picker v-model="form.arriveWhDate" type="date" value-format="yyyy-MM-dd"
										:placeholder="$t('FormMsg.Select_date_time')"></el-date-picker>
								</el-form-item> -->

							<el-form-item prop="" required>
								<template slot="label">
									<span>FBA<span>{{$t('i18nn_1272ee58ec6ee1ad')}}</span>/<span>{{$t('i18nn_7110aa855f2767a1')}}</span>/<span>{{$t('i18nn_559fed47b0e95407')}}</span></span>
								</template>
								<el-input type="text" clearable v-model="form.relationNo"
									:placeholder="$t('FormMsg.Please_Enter')"></el-input>
								<!-- <el-input type="textarea" :rows="3" placeholder="若存在多个物流单号时，请用英文逗号将物流单号分开"
										v-model="form.relationNo" style="width: 500px;">
									</el-input> -->
							</el-form-item>

							<el-form-item :label="$t('i18nn_e98a6f2582037336')" prop="" required>
								<!-- <el-select filterable clearable v-model="form.trType" :placeholder="$t('FormMsg.Please_select')"
										style="width: 150px;">
										<el-option v-for="item in selectOption.wh_transfer_Inwh_type" :key="item.code"
											:label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
									</el-select> -->
								<el-radio-group v-model="form.trType">
									<el-radio v-for="item in selectOption.wh_transfer_Inwh_type" :key="item.code"
										:label="item.code">{{ $Utils.i18nCodeText(item) }}</el-radio>

								</el-radio-group>
							</el-form-item>

							<el-form-item :label="$t('i18nn_977ed6286b514ff6')" prop="" v-if="'10'==form.trType">
								<el-select filterable clearable v-model="form.containerSize" :placeholder="$t('FormMsg.Please_select')"
									style="width: 150px;">
									<el-option v-for="item in selectOption.wh_container_size" :key="item.code"
										:label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
								</el-select>

								<!-- <el-radio-group v-model="form.containerSize">
										<el-radio v-for="item in selectOption.wh_container_size" :key="item.code" :label="item.code">{{ $Utils.i18nCodeText(item) }}</el-radio>
									</el-radio-group> -->
							</el-form-item>


							<el-form-item :label="$t('i18nn_c301b10948889cb5')" prop="">
								<el-date-picker v-model="form.planOutDate" type="date" value-format="yyyy-MM-dd"
									:placeholder="$t('FormMsg.Select_date_time')"></el-date-picker>
							</el-form-item>

						</div>
						<!-- </el-card> -->

						<!-- <el-card> -->
						<div style="flex:1;">
							<div v-if="'0'==form.isFba">
								<el-card :shadow="'never'">
									<div slot="header">
										<h3>{{$t('i18nn_8b3dbf4f5793930b')}}</h3>
									</div>
									<div>

										<el-form-item prop="" required>
											<template slot="label">
												<span>
													<span>{{$t('i18nn_f5ca68b04fa47a59')}}</span>
												</span>
											</template>
											<el-radio-group v-model="isSaveAddress">
												<el-radio :label="false">{{$t('i18nn_e76d885ae1c74d4f')}}</el-radio>
												<el-radio :label="true">{{$t('i18nn_02ccd2cf723f9272')}}</el-radio>
											</el-radio-group>
										</el-form-item>

										<div v-if="isSaveAddress">
											<el-form-item :label="$t('i18nn_e520453b3f22ad66')" prop="">
												<transferAddressSel ref="transferAddressSel" :cusUserId="cusUserId" @changeData="changeTransferAddress"
													@addSuccess="saveToAddress">
												</transferAddressSel>
												<!-- <span style="padding-left: 10px;">
													<el-checkbox v-model="isSaveAddress">{{$t('i18nn_4ce9979bfb6576d9')}}</el-checkbox>
												</span> -->
											</el-form-item>
											<el-form-item :label="$t('i18nn_20650eaf339e1fe6')" prop="" required>
												<el-input type="text" clearable v-model="addressName"
													:placeholder="$t('FormMsg.Please_Enter')"></el-input>
											</el-form-item>
											<div style="border: 1px dashed #e5e5e5; padding: 10px 0;">
												<el-form-item :label="$t('i18nn_8758fd50c87d6c9c')" prop="" required>
													<el-input clearable type="textarea" :rows="2" v-model="form.addr"
														 :maxlength="2000" show-word-limit
														:placeholder="$t('FormMsg.Please_Enter')" style="width: 220px;"></el-input>
												</el-form-item>
												<el-form-item :label="$t('c2d9cc88561f8cdc')" prop="">
													<el-input type="text" clearable v-model="form.phone"
														:placeholder="$t('FormMsg.Please_Enter')">
													</el-input>
												</el-form-item>
												<el-form-item :label="$t('i18nn_e05b5d049b64b040')" prop="" required>
													<el-input type="text" clearable v-model="form.city" :placeholder="$t('FormMsg.Please_Enter')">
													</el-input>
												</el-form-item>
												<el-form-item :label="$t('i18nn_898a2833fbeaf9e4')" prop="" required>
													<el-input type="text" clearable v-model="form.state"
														:placeholder="$t('FormMsg.Please_Enter')">
													</el-input>
												</el-form-item>
												<el-form-item :label="$t('i18nn_b166e4e8fe9513fa')" prop="" required>
													<el-input type="text" clearable v-model="form.country"
														:placeholder="$t('FormMsg.Please_Enter')"></el-input>
												</el-form-item>
												<el-form-item :label="$t('i18nn_c4913ab43009b365')" prop="" required>
													<el-input type="text" clearable v-model="form.postalCode"
														:placeholder="$t('FormMsg.Please_Enter')"></el-input>
												</el-form-item>
											</div>
										</div>


									</div>
								</el-card>

							</div>
							<div v-else>
								<el-card :shadow="'never'">
									<div slot="header">
										<h3>FBA<span>{{$t('i18nn_8758fd50c87d6c9c')}}</span></h3>
									</div>
									<div>
										<div style="border: 1px dashed #e5e5e5; padding: 10px 0;">
											<el-form-item :label="$t('i18nn_8758fd50c87d6c9c')" prop="">
												<el-input disabled clearable type="textarea" :rows="2" v-model="form.addr"
													 :maxlength="1000" show-word-limit
													style="width: 220px;"></el-input>
											</el-form-item>
											<el-form-item :label="$t('i18nn_5a9fb0915ecea987')" prop="">
												<el-input disabled type="text" clearable v-model="form.phone">
												</el-input>
											</el-form-item>
											<el-form-item :label="$t('i18nn_e05b5d049b64b040')" prop="">
												<el-input disabled type="text" clearable v-model="form.city">
												</el-input>
											</el-form-item>
											<el-form-item :label="$t('i18nn_898a2833fbeaf9e4')" prop="">
												<el-input disabled type="text" clearable v-model="form.state">
												</el-input>
											</el-form-item>
											<el-form-item :label="$t('i18nn_b166e4e8fe9513fa')" prop="">
												<el-input disabled type="text" clearable v-model="form.country"></el-input>
											</el-form-item>
											<el-form-item :label="$t('i18nn_c4913ab43009b365')" prop="">
												<el-input disabled type="text" clearable v-model="form.postalCode"></el-input>
											</el-form-item>
										</div>
									</div>
								</el-card>
							</div>


						</div>
					</div>

				</el-card>
				<el-card style="margin-top: 5px;" :body-style="{padding:'20px'}">
					<div slot="header" class="">
						<h3>{{$t('i18nn_7fba76c83eec6d09')}}</h3>
					</div>
					<div>
						<!-- <whFileUploadInner :openTime="FileUploadOpenTime" :relationId="fileRelationId" @success="FileUploadSuccess">
						</whFileUploadInner> -->
						<!-- <el-button @click="openUploadFile($event)" type="success" size="" icon="el-icon-upload2">
							{{$t('FormMsg.upload_attachment')}}
						</el-button> -->
				
						<!-- <div> -->
						<!-- <ul>
								<li v-for="(item,index) in form.fileList" :key="index">
									<el-image style="width: 50px; height: 50px;vertical-align: middle;" :z-index="9999"
										:fit="'contain'" :src="item.url" :preview-src-list="[item.url]">
									</el-image>
									<a :href="item.url" :title="item.url" target="_blank">{{ item.fileName }}</a>
								</li>
							</ul> -->
						<div>
							<el-form-item :label="$t('i18nn_f5d43732e3f6cf4d')" prop="">
								<whFileUploadInner ref="whFileUploadInner" :openTime="FileUploadOpenTime" :relationId="fileRelationId"
									@success="FileUploadSuccess">
								</whFileUploadInner>
							</el-form-item>
						</div>
				
						<div>
							<el-form-item :label="$t('i18nn_15b3627faddccb1d')" prop="">
								<el-input type="textarea" :rows="3" placeholder="" v-model="form.remark" :maxlength="1000" show-word-limit style="width: 500px;">
								</el-input>
							</el-form-item>
						</div>
				
						<!-- <el-card v-if="form.fileList && form.fileList.length > 0">
								
								<div>
									<el-table ref="multipleTable" :data="form.fileList" stripe
										style="width: 100%" size="small">
				
										<el-table-column prop="typeName" :label="$t('i18nn_184333c81babf2f1')" width="100">
										</el-table-column>
				
										<el-table-column prop="fileName" :label="$t('i18nn_1aa9b398f37906a4')">
										</el-table-column>
				
										<el-table-column prop="url" :label="$t('i18nn_d54012286fece209')">
											<template slot-scope="scope">
												<a :href="scope.row.url" target="_blank">{{ scope.row.url }}</a>
											</template>
										</el-table-column>
									</el-table>
								</div>
							</el-card> -->
						<!-- </div> -->
				
					</div>
				</el-card>
				<!-- <div style="margin-top: 12px; text-align: center;">
					<el-button @click="preStep">{{$t('i18nn_70e058bc23d52c1e')}}</el-button>
					<el-button type="primary" @click="nextStep">{{$t('4639f3a6e07c00b3')}}</el-button>
				</div> -->
			</div>






			<!-- "ctnMark": null, //$t('i18nn_fa4d50bc26e39b26'),
			"inWhCode": null, //$t('i18nn_559fed47b0e95407'),
			"inWhRecord": null, //"入库明细ID",
			"trCtnCount": null, //$t('i18nn_bdc361ba04506136'),
			"trPallte": null, //$t('i18nn_c8773d8d74202801') -->

			<!-- <div style="display: flex; margin-top:10px; border: 1px solid #eee;"> -->
			<!-- <div style="flex:1; width:50%;"> -->
			<!-- <div style=""> -->


			<!-- </div> -->
			<!-- </div> -->


			<!-- </div> -->


		</el-form>

		<!-- <div class="submit-footer" style="text-align: center;">
			<div v-if="0==stepActive">
				<el-button type="primary" @click="nextStep">{{$t('4639f3a6e07c00b3')}}</el-button>
			</div>
			<div v-else-if="1==stepActive">
				<el-button @click="preStep">{{$t('i18nn_70e058bc23d52c1e')}}</el-button>
				<el-button type="primary" @click="nextStep">{{$t('4639f3a6e07c00b3')}}</el-button>
			</div>
			<div v-else-if="2==stepActive">
				<el-button @click="preStep">{{$t('i18nn_70e058bc23d52c1e')}}</el-button>
				<el-button type="warning" @click="submitFormAction()" style="width: 200px;"
					v-if="!isOnlyView">{{$t('i18nn_e186babc2e9cadf4')}}
				</el-button>
			</div>
		</div> -->



		<!-- 要运送的货物 -->
		<!-- <div style="" v-if="!isOnlyView">
			<el-drawer :wrapperClosable="false" :title="$t('i18nn_2ad108ab2c560530')" :append-to-body="true" :size="'60%'" :visible.sync="drawer"
				:direction="'rtl'">
				<div>

					<div style="padding:10px;">
						<span>{{$t('i18nn_9d3249981866af60')}}</span>：
						<el-radio-group v-model="form.trStockSour" @change="changeTrStockSour">
							<el-radio v-for="item in selectOption.wh_tr_stock_source" :key="item.code"
								:label="item.code">{{ $Utils.i18nCodeText(item) }}</el-radio>
						</el-radio-group>
					</div>
					<div v-if="'10'==form.trStockSour">
						<TransportInWhDetPageList :openTime="TransportInWhDetOpenTime" :whNo="TransportInWhDetWhNo"
							ref="TransportInWhDetPageList">
						</TransportInWhDetPageList>
						<div style="margin: 10px;">
							<el-button icon="el-icon-check" type="warning" @click="addRec()">
								{{$t('i18nn_760f75dc397a9cb8')}}</el-button>
						</div>
					</div>
					
					<div v-else-if="'20'==form.trStockSour">
						<TransportSkuInventoriesPageList :openTime="TransportInWhDetOpenTime"
							:whNo="TransportInWhDetWhNo" ref="TransportSkuInventoriesPageList">
						</TransportSkuInventoriesPageList>
						<div style="margin: 10px;">
							<el-button icon="el-icon-check" type="warning" @click="addRecSkuInv()">
								{{$t('i18nn_760f75dc397a9cb8')}}</el-button>
						</div>
					</div>
				</div>

			</el-drawer>

		</div> -->

		<!--  附件上传 -->
		<!-- <whFileUpload :openTime="FileUploadOpenTime" :relationId="fileRelationId" @success="FileUploadSuccess">
		</whFileUpload> -->

		<!--查看图片-->
		<!-- <el-dialog :title="$t('FormMsg.picture')" append-to-body :close-on-click-modal="false"
			:visible.sync="dialogImgVisible" width="1000px" top="0">
			<div style="overflow: auto; width: 100%; height: 80%;"><img :src="imgUrlBigShow" width="auto"
					height="auto" /></div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogImgVisible = false">{{ $t('FormMsg.Close') }}</el-button>
			</div>
		</el-dialog> -->

		<!--选择sku-->
		<!-- <el-dialog :title="$t('FormMsg.Select')" append-to-body :close-on-click-modal="false" :visible.sync="dialogSelVisible" width="1200px" top="0">
			<div style=""><WSkuInfo :isSel="true" :status="'1'" @selectRow="selWhGoodsData"></WSkuInfo></div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogSelVisible = false">{{ $t('FormMsg.Close') }}</el-button>
			</div>
		</el-dialog> -->
	</div>
</template>
<script>
	import {
		deepClone
	} from '@/utils/common.js';
	
	import {
		getDicData
	} from '@/axios/common.js';
	
	// import TransshipmentImport from '@/components/StorageCenter/workOrder/workOrderTem/TransshipmentImport.vue';
	// import WSkuInfo from '@/components/StorageCenter/WSkuProduct/WSkuInfo.vue';
	// import HyQuillEditor from '@/components/Common/HyQuillEditor.vue';
	// import HyElWhUpLoad from '@/components/Common/HyElWhUpLoad.vue';
	import whNoSelect from '@/components/WarehouseCenter2/components/whNoSelect.vue';
	import transferAddressSel from '@/components/StorageCenter/components/transferAddressSel.vue';
	// import whFileUpload from '@/components/StorageCenter/components/whFileUpload.vue';
	import whFileUploadInner from '@/components/WarehouseCenter2/components/whFileUploadInner.vue';
	// import whFileUploadInner from '@/components/StorageCenter/components/whFileUploadInner.vue';
	// import HotTableTransportInWh from '@/components/StorageCenter/Transport/HotTableTransportInWh.vue';
	import TransportInWhDetListScroll from '@/components/StorageCenter/Transport/TransportInWhDetListScroll.vue';
	// import TransportSkuInventoriesListScroll from '@/components/StorageCenter/Transport/TransportSkuInventoriesListScroll.vue';
	export default {
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		// },

		// props: {
		// 	// mobile:"",
		// 	editId: {
		// 		// default: function() {
		// 		//   return false;
		// 		// },
		// 		// type: Boolean
		// 	},
		// 	openTime: {
		// 		// default: function() {
		// 		//   return '';
		// 		// },
		// 		// type: String
		// 	}
		// },
		components: {
			// WSkuInfo,
			// HyElWhUpLoad,
			// HyQuillEditor,
			// TransshipmentImport
			TransportInWhDetListScroll,
			// TransportSkuInventoriesListScroll,
			whNoSelect,
			transferAddressSel,
			// whFileUpload,
			whFileUploadInner
			// HotTableTransportInWh
			// whFileUploadInner
		},
		data() {
			return {
				cusUserId:"",
				stepActive: 0,
				serKeyword:"",
				// UserInfo: this.$store.getters.getUserInfo,
				// dialogFormVisible: false,
				// dialogFormStatus: 0, //0-新增，1-修改
				// dialogFormMsg: '',
				editId: '',
				//导入excel
				// dialogUploadVisible: false,
				loadingExcel: false,
				fileExcel: '',
				// excelData: [],
				excelFileName: '',

				fileRelationId: '',
				FileUploadOpenTime: '',

				TransportInWhDetOpenTime: "",
				TransportInWhDetWhNo: "",

				drawer: false,

				//图片放大
				// dialogImgVisible: false,
				// imgUrlBigShow: '',

				//打开SKU选择框
				// dialogSelVisible: false,
				// openRowData: {},
				// openRowIndex: {},

				loading: false,

				loading_det: false,

				isSaveAddress: false,
				addressName: "",

				// fbaAddress:{},

				// isShowSkuCheck: false,
				form: {
					"whNo": null, //this.$t('c944a6686d996ab3'),
					"isFba": '1', //"是否FBA",
					"fbaAddrCode": null, //"FBA仓库编号",
					"relationNo": null, //"FBA申请号/物流号/转运单号",
					"country": null, //this.$t('b166e4e8fe9513fa'),
					"state": null, //"省/州",
					"city": null, //this.$t('e05b5d049b64b040'),
					"postalCode": null, //this.$t('c4913ab43009b365'),
					"addr": null, //this.$t('i18nn_8758fd50c87d6c9c'),
					"phone": null, //this.$t('c2d9cc88561f8cdc'),
					"trType": null, //this.$t('i18nn_e98a6f2582037336'),
					"containerSize": null, //柜型
					"trStockSour": "10",
					"recordList": [
						// {
						// "ctnMark": null, //this.$t('i18nn_fa4d50bc26e39b26'),
						// "inWhCode": null, //this.$t('i18nn_559fed47b0e95407'),
						// "inWhRecord": null, //"入库明细ID",
						// "trCtnCount": null, //this.$t('i18nn_bdc361ba04506136'),
						// "trPallte": null, //this.$t('i18nn_c8773d8d74202801')
						// },
					],
				},

				formRules: {
					sendNo: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],
					recevieName: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],
					whNo: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],
					recevieMobile: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],
					address1: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],
					carTmsType: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'change'
					}],
					postCode: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],
					country: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}], //this.$t('b166e4e8fe9513fa'),
					province: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}], //"省/州",
					city: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}], //this.$t('e05b5d049b64b040'),
					// address1: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }], //this.$t('i18nn_8db45b615b72b7a8'),
					// carTmsType: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }], //this.$t('5165f3ee9377af83'),
					carPlanDate: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}] //this.$t('99b88de52e382ca4'),
				},
				loading_load: false,
				selectOption: {
					wh_size_unit: [],
					wh_transfer_Inwh_type: [],
					wh_transfer_arrive_way: [],
					wh_tr_stock_source: [],
					wh_container_size: []
				},

				dialogFormStatus: 0, //0-新增，1-修改

				isOnlyView: false,

			};
		},
		// watch: {
		// 	'$route.query': function(newVal, oldVal) {
		// 		console.log('$route.query', newVal);
		// 		// if(newVal){
		// 		// this.dialogFormVisible = true;
		// 		this.initData();
		// 		// }
		// 	}
		// },
		activated() {
			this.initData();
		},
		//创建时
		created() {
			// this.getPageData();
			// this.initData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			//数据字典
			getDicData(['wh_transfer_Inwh_type', 'wh_tr_stock_source',
						'wh_container_size'
					],
				(data)=>{
					this.selectOption.wh_transfer_Inwh_type = data['wh_transfer_Inwh_type'];
					this.selectOption.wh_tr_stock_source = data['wh_tr_stock_source'];
					this.selectOption.wh_container_size = data['wh_container_size'];
			});
		},
		methods: {
			initData() {
				this.stepActive = 0;
				this.form = {
					"whNo": null, //this.$t('c944a6686d996ab3'),
					"isFba": '1', //"是否FBA",
					"fbaAddrCode": null, //"FBA仓库编号",
					"relationNo": null, //"FBA申请号/物流号/转运单号",
					"country": null, //this.$t('b166e4e8fe9513fa'),
					"state": null, //"省/州",
					"city": null, //this.$t('e05b5d049b64b040'),
					"postalCode": null, //this.$t('c4913ab43009b365'),
					"addr": null, //this.$t('i18nn_8758fd50c87d6c9c'),
					"phone": null, //this.$t('c2d9cc88561f8cdc'),
					"trType": null, //this.$t('i18nn_e98a6f2582037336'),
					"containerSize": null, //柜型
					"trStockSour": "10",
					"recordList": [
						// {
						// "ctnMark": null, //this.$t('i18nn_fa4d50bc26e39b26'),
						// "inWhCode": null, //this.$t('i18nn_559fed47b0e95407'),
						// "inWhRecord": null, //"入库明细ID",
						// "trCtnCount": null, //this.$t('i18nn_bdc361ba04506136'),
						// "trPallte": null, //this.$t('i18nn_c8773d8d74202801')
						// },
					],
				};
				this.editId = "";
				
				this.$nextTick(() => {
					this.$refs.whNoSelect.init("");
				});
				// this.$nextTick(() => {
				// 	this.$refs.whFileUploadInner.initData();
				// });
				
				if (this.$route.query.cusUserId) {
					this.cusUserId = this.$route.query.cusUserId;
				} else {
					this.cusUserId = "";
				}
				
				// this.$nextTick(() => {
				// 	this.$refs.HotTableTransport.clear();
				// });
				// this.isOnlyView = false;
				if (this.$route.query.editId) {
					this.editId = this.$route.query.editId;
					//不允许编辑
					// this.hasEdit = false;
					this.getPageBaseData(this.editId);
					// this.isOnlyView = true;
					// this.fileRelationId = this.editId;
				} else {
					this.initTransportInventory();
				}

				this.openUploadFile();
				// this.FileUploadOpenTime = new Date().getTime();
				// else {
				// 	this.hasEdit = true;
				// }
			},
			//仓库列表加载成功
			whLoadSuccess(list){
				if (!this.$route.query.editId) {//新增时
					this.$nextTick(()=>{
						if(list.length>0){
							let defVal = "";
							defVal = list[0].code;
							this.$refs.whNoSelect.init(defVal);
							this.form.whNo = defVal;
							this.initTransportInventory();
						}
					});
				}
			},
			serTranInv(){
				this.initTransportInventory();
			},
			preStep() {
				this.stepActive--;
			},
			nextStep() {
				if(0==this.stepActive){
					if(!this.form.whNo){
						this.$message.warning(this.$t('i18nn_f217cfe6c6291a89'));
						return;
					}
					if(this.form.recordList.length<=0){
						this.$message.warning(this.$t('i18nn_80755d56db88fca2'));
						return;
					}
				} else if(1==this.stepActive){
					// if(!this.form.relationNo){
					// 	this.$message.warning("请填写FBA申请号或物流号");
					// 	return;
					// }
					// if(!this.form.trType){
					// 	this.$message.warning("请选择装运类型");
					// 	return;
					// }
					// if('1'==this.form.isFba && !this.form.fbaAddrCode){
					// 	this.$message.warning("请填写FBA仓库编号");
					// 	return;
					// }
				}
				this.stepActive++;
			},
			goBack() {
				this.$router.go(-1);
				// this.$router.push({
				// 	name: 'TransportOutWh'
				// });
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.form.whNo = data.code;
				//清空已选择的货物
				this.form.recordList = [];

				this.initTransportInventory();
				// this.filterData.userId = data.userId;
				// this.initData();
			},
			changeTransferAddress(data) {
				console.log('changeTransferAddress', data);
				// this.form.whNo = data.code;
				// this.filterData.userId = data.userId;
				// this.initData();
				this.addressName = data.name;
				this.form.addr = data.address;
				this.form.city = data.city;
				this.form.state = data.state;
				this.form.country = data.country;
				this.form.postalCode = data.zipCode;

				// "name": this.addressName, //this.$t('i18nn_59764ba919e3d70d'),
				// "address": this.form.addr, //this.$t('i18nn_8758fd50c87d6c9c'),
				// "city": this.form.city, //this.$t('e05b5d049b64b040'),
				// "state": this.form.state, //this.$t('i18nn_7cab79716236ebb0'),
				// "zipCode": this.form.postalCode, //this.$t('c4913ab43009b365'),
				// "country": this.form.country, //"US"
			},

			//初始化转运库存
			initTransportInventory() {
				this.TransportInWhDetOpenTime = new Date().getTime();
				this.TransportInWhDetWhNo = this.form.whNo;
			},
			//初始化sku库存
			// initInStoreInventory(){
			// 	this.TransportInWhDetOpenTime = new Date().getTime();
			// 	this.TransportInWhDetWhNo = this.form.whNo;
			// },
			//转运库存数量变化
			TransportInvChange(list) {
				//新增
				let HasRepeat = false; //是否存在重复
				let new_recordList = list.filter(item => {
					return item.trCtnCount > 0;
				});
				console.log('new_recordList', new_recordList);
				// let new_recordList_ids = new_recordList.map(item=>{return item.id})
				let old_recordList = deepClone(this.form.recordList);

				new_recordList.map(item => {
					let hasIndex = old_recordList.findIndex(item2 => {
						return item2.inWhRecord == item.id;
					});
					if (hasIndex > -1) { //存在
						let newObj = this.form.recordList[hasIndex];
						newObj.trCtnCount = item.trCtnCount;
						this.$set(this.form.recordList, hasIndex, newObj);
					} else { //不存在
						this.form.recordList.push({
							"ctnMark": item.ctnMark, //this.$t('i18nn_fa4d50bc26e39b26'),
							"inWhCode": item.inWhCode, //this.$t('i18nn_559fed47b0e95407'),
							"inWhRecord": item.id, //"入库明细ID",
							"trCtnCount": item.trCtnCount, //this.$t('i18nn_bdc361ba04506136'),
							"trStockSour": this.form.trStockSour, //货物来源
							"goodsSku": item.goodsSku,
							"remark": null, //备注
							// "remark":item.remark,//备注
							// "trPallte": item.inWhPallte, //this.$t('i18nn_c8773d8d74202801')
						});
					}
				});

			},

			//sku库存改变
			SkuInvChange(list) {
				//新增
				let HasRepeat = false; //是否存在重复
				let new_recordList = list.filter(item => {
					return item.trCtnCount > 0;
				});
				console.log('new_recordList', new_recordList);
				// let new_recordList_ids = new_recordList.map(item=>{return item.id})
				let old_recordList = deepClone(this.form.recordList);

				new_recordList.map(item => {
					let hasIndex = old_recordList.findIndex(item2 => {
						return item2.ctnMark == item.goodsSku;
					});
					if (hasIndex > -1) { //存在
						let newObj = this.form.recordList[hasIndex];
						newObj.trCtnCount = item.trCtnCount;
						this.$set(this.form.recordList, hasIndex, newObj);
					} else { //不存在
						this.form.recordList.push({
							"ctnMark": item.goodsSku, //this.$t('i18nn_fa4d50bc26e39b26'),
							"inWhCode": '', //this.$t('i18nn_559fed47b0e95407'),
							"inWhRecord": null, //"入库明细ID",
							"trCtnCount": item.trCtnCount, //this.$t('i18nn_bdc361ba04506136'),
							"trStockSour": this.form.trStockSour, //货物来源
							"goodsSku": item.goodsSku,
							"remark": null, //备注
						});
					}
				});
			},

			//打开选项抽屉
			// openDrawer() {
			// 	if (!this.form.whNo) {
			// 		this.$message.warning(this.$t('FormMsg.Select_long_out_wh'));
			// 		return;
			// 	}
			// 	// if (!this.form.trStockSour) {
			// 	// 	this.$message.warning(this.$t('FormMsg.Select_long_goods_source'));
			// 	// 	return;
			// 	// }
			// 	this.form.trStockSour = "10"; //默认转运入库-源
			// 	this.drawer = true;
			// 	//查询对应仓库数据
			// 	this.TransportInWhDetOpenTime = new Date().getTime();
			// 	this.TransportInWhDetWhNo = this.form.whNo;
			// 	this.$forceUpdate();
			// },
			// changeTrStockSour(V) {
			// 	//查询对应仓库数据
			// 	// this.TransportInWhDetOpenTime = new Date().getTime();
			// 	// this.TransportInWhDetWhNo = this.form.whNo;
			// 	this.$forceUpdate();
			// },
			handleClick(tab, event) {
				console.log(tab, event);
			},
			//添加的选择的数据-转运的货物
			// addRec() {
			// 	this.$nextTick(() => {
			// 		let selData = this.$refs.TransportInWhDetListScroll.getSelData();
			// 		console.log("selData", selData);
			// 		if (selData.length <= 0) {
			// 			this.$message.warning(this.$t('i18nn_fc089dc894530f12'));
			// 			return;
			// 		}
			// 		//新增
			// 		let HasRepeat = false; //是否存在重复
			// 		let new_recordList = [];
			// 		let old_recordList = this.form.recordList;
			// 		selData.map(item => {
			// 			let hasIndex = old_recordList.findIndex(item2 => {
			// 				return item2.inWhRecord == item.id;
			// 			});
			// 			if (hasIndex > -1) { //存在
			// 				HasRepeat = true;
			// 			} else {
			// 				let trCtnCount = parseFloat(item.stock ? item.stock : 0) - parseFloat(item
			// 					.lockTotal ? item.lockTotal : 0);
			// 				new_recordList.push({
			// 					"ctnMark": item.ctnMark, //this.$t('i18nn_fa4d50bc26e39b26'),
			// 					"inWhCode": item.inWhCode, //this.$t('i18nn_559fed47b0e95407'),
			// 					"inWhRecord": item.id, //"入库明细ID",
			// 					"trCtnCount": trCtnCount, //this.$t('i18nn_bdc361ba04506136'),
			// 					"trStockSour": this.form.trStockSour, //货物来源
			// 					"goodsSku": item.goodsSku,
			// 					"remark": null, //备注
			// 					// "remark":item.remark,//备注
			// 					// "trPallte": item.inWhPallte, //this.$t('i18nn_c8773d8d74202801')
			// 				});
			// 			}
			// 		});
			// 		if (HasRepeat) {
			// 			this.$message.success("已自动过滤重复数据");
			// 		}
			// 		this.form.recordList = this.form.recordList.concat(new_recordList);

			// 		// this.drawer = false;
			// 	});
			// },
			//添加的选择的数据-库存的货物
			// addRecSkuInv() {
			// 	this.$nextTick(() => {
			// 		let selData = this.$refs.TransportSkuInventoriesListScroll.getSelData();
			// 		console.log("selData", selData);
			// 		if (selData.length <= 0) {
			// 			this.$message.warning(this.$t('i18nn_fc089dc894530f12'));
			// 			return;
			// 		}
			// 		//新增
			// 		let HasRepeat = false; //是否存在重复
			// 		let new_recordList = [];
			// 		let old_recordList = this.form.recordList;
			// 		selData.map(item => {
			// 			let hasIndex = old_recordList.findIndex(item2 => {
			// 				return item2.ctnMark == item.goodsSku;
			// 			});
			// 			if (hasIndex > -1) { //存在
			// 				HasRepeat = true;
			// 			} else {
			// 				new_recordList.push({
			// 					"ctnMark": item.goodsSku, //this.$t('i18nn_fa4d50bc26e39b26'),
			// 					"inWhCode": '', //this.$t('i18nn_559fed47b0e95407'),
			// 					"inWhRecord": null, //"入库明细ID",
			// 					"trCtnCount": item.currentStock, //this.$t('i18nn_bdc361ba04506136'),
			// 					"trStockSour": this.form.trStockSour, //货物来源
			// 					"goodsSku": item.goodsSku,
			// 					"remark": null, //备注
			// 					// "remark":item.remark,//备注
			// 					// "trPallte": item.inWhPallte, //this.$t('i18nn_c8773d8d74202801')
			// 				});
			// 			}
			// 		});
			// 		if (HasRepeat) {
			// 			this.$message.success("已自动过滤重复数据");
			// 		}
			// 		this.form.recordList = this.form.recordList.concat(new_recordList);

			// 		this.drawer = false;
			// 	});
			// },

			//新增
			// addDet() {
			// 	this.form.recordList.push({
			// 		"ctnMark": null, //this.$t('i18nn_8a43886ff356ed78'),
			// 		"ctnCount": null, //this.$t('i18nn_53fa7ff1ea383b2f'),
			// 		"goodsSku": null, //"箱内SKU",
			// 		"ctnSkuCount": null, //this.$t('i18nn_85a10d3e5ea0e103'),
			// 		"inWhPallte": null, //this.$t('i18nn_18c1c1a3ea76e9d3'),
			// 		"skuTotal": null, //this.$t('i18nn_bad53577db0da2d3'),
			// 		"ctnL": null, //this.$t('i18nn_70b5c883b227851b'),
			// 		"ctnW": null, //this.$t('i18nn_0a389c3a5e3938d3'),
			// 		"ctnH": null, //this.$t('i18nn_5c31dfdb8f4781ec'),
			// 		"ctnWeight": null, //this.$t('i18nn_91263f8fa1a94da9'),
			// 		"ctnUnit": null, //this.$t('b6bf0a07fe26f74f')
			// 	});
			// },
			// 删除
			delDet(event, row, index) {
				event.stopPropagation();
				this.form.recordList.splice(index, 1);
			},
			// parsingSuccess(data){
			// 	this.formBase.sendDto = data;
			// },
			// changeAgentUser(val){
			// 	// this.filterData.agentUser = val;
			// 	// this.initData();
			// },
			//跳转页面
			// toPageUrl(name){
			//   this.$router.push({'name':name});
			// },
			//提交(先提交附件，回调之后提交新增或者编辑方法)
			submitFormAction() {
				this.$refs['form'].validate(valid => {
					if (valid) {
						this.$nextTick(() => {
							this.$refs.whFileUploadInner.submitExcelFormAction();
						});
					} else {
						console.log('error submit!!');
						this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('204ffab8a6e01870'),
						});
						return false;
					}
				});
			},
			//打开附件上传
			openUploadFile() {
				// event.stopPropagation();
				// this.isShowFileUpload = true;
				this.fileRelationId = this.editId;
				this.FileUploadOpenTime = new Date().getTime();
			},
			//附件上传成功回调
			FileUploadSuccess(data) {
				console.log("FileUploadSuccess", data);
				// this.initData();
				this.form.fileList = data;

				// return;
				this.submitForm('form');
				this.$forceUpdate();
				// event.stopPropagation();
				// this.isShowFileUpload = true;
				// this.fileRelationId = row.id;
				// this.FileUploadOpenTime = new Date().getTime();
			},
			//查询FBA地址
			searchFbaAddress() {
				console.log('searchFbaAddress');
				if ('' == this.form.fbaAddrCode) {
					this.$message.warning(this.$t('5a9aefbc03c778f7')+'FBA'+this.$t('i18nn_a68426dce989b497'));
					return;
				}
				this.form.country = "";
				this.form.state = "";
				this.form.city = "";
				this.form.postalCode = "";
				this.form.addr = "";
				this.form.phone = "";
				
				this.loading = true;
				this.$http
					.get(this.$urlConfig.WhTransferQueryFba + '?fbaCode=' + this.form.fbaAddrCode, {})
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_776496ec446f21f9'));
						console.log(data);
						this.loading = false;
						if (200 == data.code && data.data) {
							let addrData = data.data;
							this.form.country = addrData.country;
							this.form.state = addrData.state;
							this.form.city = addrData.city;
							this.form.postalCode = addrData.zipCode;
							this.form.addr = addrData.address;
							this.form.phone = addrData.phone;

							// this.fbaAddress = data.data;

						} else {
							// if (!data.msg) {
							// 	data.msg = this.$t('i18nn_d74d0bd89431d6d5');
							// }
							// this.$message.warning(data.msg);
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.errorData'));
						this.loading = false;
						// this.$message.error('查询失败!');
					});
			},

			//打开新增编辑，弹窗
			// openDioalog(formParm, msg) {
			// 	// console.log(formParm);
			// 	this.dialogFormVisible = true;
			// 	let form = Object.assign({}, formParm);
			// 	console.log('form', form);
			// 	this.dialogFormMsg = msg;
			// 	// // 重置
			// 	this.resetForm('form');
			// 	if (null === formParm) {
			// 		//新增

			// 		// this.form.weightUnit = '1';
			// 		// this.form.volumeUnit = '1';
			// 		// this.form.whFeeType = '1';

			// 		this.dialogFormStatus = 0;
			// 		// form.subUserId = null;
			// 		// form.userSubUserId = null;
			// 		// form.state = true;
			// 		//浅拷贝、对象属性的合并
			// 		this.form = form;
			// 	} else {
			// 		//修改
			// 		this.dialogFormStatus = 1;
			// 		// form.state = form.state === '0' ? true : false;

			// 		//浅拷贝、对象属性的合并
			// 		this.form = form;
			// 	}

			// 	this.$forceUpdate();
			// 	// this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
			// 	//       type: 'warning',
			// 	//       //confirmButtonText: this.$t('204ffab8a6e01870'),
			// 	//     });
			// },
			// 富文本
			// onEditorChange(html) {
			// 	this.form.contentStr = html;
			// },
			//打开编辑
			// openEdit(event, row, index) {
			// 	event.stopPropagation();
			// 	this.openDioalog(row, this.$t('FormMsg.Edit'));
			// 	this.getPageDetData(row.id);
			// },
			//请求分页数据
			getPageBaseData(id) {
				// let _this = this;
				this.loading = true;
				this.$http
					.get(this.$urlConfig.WhTransferOutEditQuery + '/' + id, {})
					.then(({
						data
					}) => {
						console.log('分页，请求成功');
						console.log(data);
						this.loading = false;
						this.form = data.data;
						if (this.form.recordList && this.form.recordList[0]) {
							this.form.trStockSour = this.form.recordList[0].trStockSour;
						}

						// this.getPageDetData(this.editId);
						this.$nextTick(() => {
							this.$refs.whNoSelect.init(this.form.whNo);
							this.initTransportInventory();
						});
						if (0 != data.data.status && 10 != data.data.status) {
							this.isOnlyView = true;
						}

						this.isSaveAddress = this.form.addr ? true : false;
						// this.isShowSkuCheck = this.form.isShowSku == 1 ? true : false;
						// this.form.isShowSku = this.form.isShowSku==1?true:false;
						// this.$nextTick(() => {
						// console.log('this.form.recordList', this.form.recordList);
						// this.$refs.HotTableTransport.loadData(this.form.recordList);
						// });
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading = false;
					});
			},
			//明细
			//请求分页数据
			// getPageDetData(id) {
			// 	// let _this = this;
			// 	this.loading = true;
			// 	this.$http
			// 		.get(this.$urlConfig.WhTransferDetQuery + '/' + id, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('分页，请求成功');
			// 			console.log(data);
			// 			this.loading = false;
			// 			// this.form = data.data;
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('分页，请求失败');
			// 			this.loading = false;
			// 		});
			// },
			//打开选择数据
			// openSelData(event, row, index) {
			// 	event.stopPropagation();

			// 	this.dialogSelVisible = true;
			// 	this.openRowData = row;
			// 	this.openRowIndex = index;
			//  // this.$nextTick(() => {
			// 	//   this.$refs.hyUpLoadImg1.openFile();
			// 	// });
			// },
			// //选择数据后回调
			// selWhGoodsData(selRow) {
			// 	this.dialogSelVisible = false;
			// 	console.log('selRow', selRow);
			// 	let row = this.openRowData;
			// 	// row.putWhNo = selRow.putWhNo;
			// 	// row.plPlaceNo = selRow.placeNo;
			// 	row.goodsSku = selRow.goodsSku;
			// 	// row.sendAcount = 0;
			// 	// row.mySkuDto = selRow;

			// 	this.$set(this.form.recordList, this.openRowIndex, row);
			// },
			//查看图片
			// openBigImg(event, imgsrc) {
			// 	event.stopPropagation();
			// 	console.log('openBigImg', imgsrc);
			// 	this.dialogImgVisible = true;
			// 	this.imgUrlBigShow = imgsrc;
			// },
			// fileChange(e) {
			// 	// this.loading = true;
			// 	console.log(e);

			// 	console.log(this.$t('i18nn_f156a9536049f461'), this.$refs.file);
			// 	// this.excelData = [];
			// 	this.form.sendRecordDtoList = [];
			// 	(this.fileExcel = null), (this.excelFileName = '');
			// 	if (!this.$refs.file || !this.$refs.file.files) {
			// 		this.$alert(this.$t('module.upload.Unknown_file'), this.$t('tips.tipsTitle'), {
			// 			// //confirmButtonText: this.$t('204ffab8a6e01870'),
			// 		});
			// 		return;
			// 	}
			// 	if (this.$refs.file.files[0]) {
			// 		// if (this.fileAccept.indexOf(this.$refs.file.files[0].type) > -1) {
			// 		if (this.$refs.file.files[0].size < 1024 * 1024 * 10) {
			// 			this.fileExcel = this.$refs.file.files[0];
			// 			this.excelFileName = this.fileExcel.name;
			// 			// console.log(this.$t('i18nn_f156a9536049f461'),this.$refs.file);
			// 			// this.uploadExcel();
			// 		} else {
			// 			this.$alert(this.$t('module.upload.uploaded_over') + '10M', this.$t('tips.tipsTitle'), {});
			// 		}
			// 		// } else {
			// 		//   this.$alert(this.$t(this.$t('i18nn_4dadf13ebce2167d')), this.$t('tips.tipsTitle'), {
			// 		//   });
			// 		// }
			// 	} else {
			// 		// this.$alert(this.$t('i18nn_c8e99e36cefd743a'), this.$t('tips.tipsTitle'), {
			// 		// });
			// 		console.log(this.$t('i18nn_c8e99e36cefd743a'), this.$refs.file);
			// 	}
			// },
			// clearFile(){
			// 	this.fileExcel = null;
			// 	this.excelFileName = '';
			// 	// this.excelFileName = "";
			// 	this.$nextTick(() => {
			// 		try {
			// 			this.$refs.file.value = '';
			// 		} catch (e) {
			// 			console.log(e);
			// 			//TODO handle the exception
			// 		}
			// 	});
			// },
			// openExcel() {
			// 	try {
			// 		this.$refs.file.value = '';
			// 	} catch (e) {
			// 		console.log(e);
			// 		//TODO handle the exception
			// 	}
			// 	// this.$refs.file.value = '';
			// 	// this.$refs.file.dispatchEvent(new MouseEvent('click'));
			// 	var comment = this.$refs.file;
			// 	if (document.all) {
			// 		// For IE
			// 		comment.click();
			// 	} else if (document.createEvent) {
			// 		// 在ff中要为a标签添加click事件，并且侦听该事件
			// 		var ev = document.createEvent('MouseEvents');
			// 		ev.initEvent('click', false, true);
			// 		comment.dispatchEvent(ev);
			// 	} else {
			// 		// this.$alert('打开上传文件有误，请联系客服', this.$t('tips.tipsTitle'), {
			// 		this.$alert(this.$t('module.upload.open_error_img'), this.$t('tips.tipsTitle'), {
			// 			// //confirmButtonText: this.$t('204ffab8a6e01870'),
			// 		});
			// 	}
			// },
			//导入excel
			// uploadExcel() {
			// 	if (!this.fileExcel) {
			// 		this.$alert(this.$t('module.upload.Please_sel_file'), this.$t('tips.tipsTitle'), {});
			// 		return;
			// 	}

			// 	this.loadingExcel = true;

			// 	let file = this.fileExcel;
			// 	var formdata = new FormData();
			// 	formdata.append('file', file);

			// 	this.$http
			// 		.post(this.$urlConfig.WhShipmentsListExUpload, formdata, {
			// 			headers: {
			// 				'Content-Type': 'multipart/form-data'
			// 			}
			// 		})
			// 		.then(({ data }) => {
			// 			console.log(this.$t('i18nn_bc868e024b80d2e3'));
			// 			console.log(data);
			// 			this.loadingExcel = false;
			// 			if (200 == data.code && data.rows) {
			// 				// this.excelData = data.rows;

			// 				this.form.sendRecordDtoList = data.rows;

			// 				this.$forceUpdate();
			// 				this.clearFile();
			// 				// if('1'==data.data.flag){
			// 				//   this.excelData = data.data.data;
			// 				// } else {
			// 				//   this.$alert(data.data.errorMsg, this.$t('tips.tipsTitle'), {
			// 				//     type: 'warning',
			// 				//     //confirmButtonText: this.$t('204ffab8a6e01870'),
			// 				//   });
			// 				// }
			// 			} else {
			// 				this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
			// 					type: 'warning'
			// 					//confirmButtonText: this.$t('204ffab8a6e01870'),
			// 				});
			// 			}
			// 			// if (200 == data.code && data.data) {
			// 			//   if('1'==data.data.flag){
			// 			//     this.excelData = data.data.data;
			// 			//   } else {
			// 			//     this.$alert(data.data.errorMsg, this.$t('tips.tipsTitle'), {
			// 			//       type: 'warning',
			// 			//       //confirmButtonText: this.$t('204ffab8a6e01870'),
			// 			//     });
			// 			//   }

			// 			// } else {
			// 			//   this.$alert(data.msg?data.msg:this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
			// 			//     type: 'warning',
			// 			//     //confirmButtonText: this.$t('204ffab8a6e01870'),
			// 			//   });
			// 			// }
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('tips.submitError'));
			// 			this.loadingExcel = false;
			// 			this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
			// 				type: 'warning'
			// 				//confirmButtonText: this.$t('204ffab8a6e01870'),
			// 			});
			// 		});
			// },

			//上传回调
			// changeFileUrl(val) {
			// 	console.log('changeFileUrl', val);
			// 	let listUrl = val.map(item => {
			// 		// "filePath":"","fileName"：“”
			// 		return {
			// 			url: item.url,
			// 			fileName: item.name
			// 		};
			// 	});

			// 	this.form.attachments = listUrl;
			// },
			submitData(fbaAddrCode) {
				console.log('form', this.form);
				let formData = Object.assign({}, this.form);
				if (fbaAddrCode) {
					formData.fbaAddrCode = fbaAddrCode;
				}
				console.log('formData', formData);
				if (this.editId) {
					// formData.id = this.editId;
					this.postData(this.$urlConfig.WhTransferOutEdit+"/"+this.cusUserId, formData, () => {
						console.log('submitSuccess');
						// this.$emit('submitSuccess');
						this.goBack();
					});
				} else {
					this.postData(this.$urlConfig.WhTransferOutAdd+"/"+this.cusUserId, formData, () => {
						console.log('submitSuccess');
						// this.$emit('submitSuccess');
						this.goBack();
					});
				}
			},
			//提交信息
			submitForm() {
				//不是FBA,且地址名称为空
				if ('0' == this.form.isFba && this.isSaveAddress) {
					this.saveAddress();
				} else {
					this.submitData();
				}
			},

			saveAddress() {
				this.$nextTick(() => {
					let id = null;
					// if(this.$refs.transferAddressSel){
						id = this.$refs.transferAddressSel.getId()
					// }
					let address_formData = {
						"id": id ? id : null, //"数据ID",
						"name": this.addressName, //this.$t('i18nn_59764ba919e3d70d'),
						"address": this.form.addr, //this.$t('i18nn_8758fd50c87d6c9c'),
						"city": this.form.city, //this.$t('e05b5d049b64b040'),
						"state": this.form.state, //this.$t('i18nn_7cab79716236ebb0'),
						"zipCode": this.form.postalCode, //this.$t('c4913ab43009b365'),
						"country": this.form.country, //"US"
						phone: this.form.phone, //"phone"
					};
					// if(this.$refs.transferAddressSel){
						this.$refs.transferAddressSel.addData(address_formData);
					// }
				})
			},
			//保存地址回调
			saveToAddress(data) {
				if (data && 200 == data.code && data.data && data.data.code) {
					this.submitData(data.data.code);
				} else {
					this.submitData();
				}
			},
			//重置输入框
			// resetForm(formName) {
			// 	console.log(formName);
			// 	// console.log(this.$refs[formName]);

			// 	this.$nextTick(() => {
			// 		if (this.$refs[formName]) {
			// 			this.$refs[formName].resetFields();
			// 			// if ('form' === formName) {
			// 			// 	this[formName]['packRecordList'] = [];
			// 			// }
			// 		} else {
			// 			console.log('this.$refs[formName]', this.$refs[formName]);
			// 		}
			// 	});
			// 	this.$nextTick(() => {
			// 		try {
			// 			this.$refs.HyElWhUpLoad.initData();
			// 			this.$refs.HyElWhUpLoad.clearFileList();
			// 		} catch (err) {
			// 			console.log(err);
			// 		}
			// 	});
			// 	this.fileList = [];
			// },

			//提交信息
			postData(url, formData, callback) {
				// HttpTypelet _this = this;
				this.loading = true;
				// this.loading_load = true;
				let HttpType = {};
				// if ('delete' == type) {
				// 	HttpType = this.$http.delete(url, formData);
				// } else {
				HttpType = this.$http.put(url, formData);
				// }
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);
					this.loading = false;
					if (200 == data.code) {
						this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
							type: 'success'
						});
						callback();
						// }
					} else {
						// if (!data.msg) {
						//   data.msg = this.$t('tips.submitError');
						// }
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					// this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('204ffab8a6e01870'),
					});
				});
			},
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_transfer_Inwh_type', 'wh_tr_stock_source',
			// 			'wh_container_size'
			// 		])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 				// this.selectOption.wh_size_unit = data.data['wh_size_unit'];
			// 				this.selectOption.wh_transfer_Inwh_type = data.data['wh_transfer_Inwh_type'];
			// 				this.selectOption.wh_tr_stock_source = data.data['wh_tr_stock_source'];
			// 				this.selectOption.wh_container_size = data.data['wh_container_size'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.queryErrorFailed');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.queryRequestFailed'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>